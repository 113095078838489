import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { EventLayout } from '../events'
import { Jumbotron, Media, Button } from 'reactstrap'
import ReactHtmlParser from 'react-html-parser'
import ReactPlayer from 'react-player'
import qs from 'query-string'
import { handleGetPrivateInvitationDetails, handleInvitationResponse } from '../../services/api'
import { BarLoader } from 'react-spinners'
import {navigate}  from '@reach/router'
import moment from 'moment'
import { UncontrolledAlert } from 'reactstrap'
import { isLoggedIn } from "../../services/auth"

const override = `
    margin: 0 auto;
`

const user = isLoggedIn() ? JSON.parse(window.localStorage.getItem("gatsbyUser")) : false

export default class view extends Component {
    constructor(props){
        super(props)

        let queryData = null

        let status = false
        let message = ""
        let style = {}

        if(props.location.search !== ""){
            queryData = qs.parse(props.location.search, { ignoreQueryPrefix: true })
        }

        // TODO: Add form states and validation states for the event registration
        this.state = {
            data : null,
            id : !queryData ? null : queryData.event_id,
            invitation_code : !queryData ? null : queryData.invitation_code,
            status : status,
            statusMessage : message,
            style : style,
            isReplyBtnLoading : false
        }
    }

    // Load Event data asynchronously
    async componentDidMount(){
        
        let data = await handleGetPrivateInvitationDetails({event_id : this.state.id, invitation_code: this.state.invitation_code})
        console.log(data)

        if(data.length === 0){
            await navigate('/events/' , {state : {status : "danger", message : "The event post you are trying to access is either deleted or moved."}})
            if(typeof window !== "undefined"){
                window.location.reload()
            } 
        }
        
        this.setState({
            data : data[0]
        })
        
               
    }

    captchaChange = (value) => {
        const token = value
  
    this.setState({
        captcha : token
    }, () => {
        this.validateForm()
    })
    }

    //accept and decline invitation
    acceptInvitation = async (response) => {
        this.setState({
            isReplyBtnLoading: true
        })
        await handleInvitationResponse({event_id : this.state.id, invitation_code: this.state.invitation_code, response: response})
        .then(async (res) => {
            console.log('invitation response')
            console.log(res)
            this.setState({
                isReplyBtnLoading: false
            })
            await navigate('/events/' , {state : {status : "success", message : "Thank you for your prompt response on the invitation."}})
        }).catch(err => {
            console.log(err)
            this.setState({
                isReplyBtnLoading: false
            })
        })
    }
    //end invitation

    renderInvitationResponseBtn() {
    var today = new Date()
    if(Date.parse(this.state.data.registration_end_date) < Date.parse(today) || (this.state.data.capacity != 'unlimited' && this.state.data.total_reg >= this.state.data.capacity)){
        return (
            <div><i>Event registration is close</i></div>
        );
    }else if(this.state.data.attendence == 'Yes' || this.state.data.attendence == 'No'){
        return (
            <div><i>You have already response to this event invitation</i></div>
        );
    }else{
        return (
            <div>
                <Button size="lg" className="mx-2 btn btn-outline-success" disabled={this.state.isReplyBtnLoading} onClick={this.acceptInvitation.bind(this, 'accept')}>{this.state.isReplyBtnLoading ? 'Loading…' : 'Attending'}</Button>
                <Button size="lg" className="mx-2 btn btn-outline-danger" disabled={this.state.isReplyBtnLoading} onClick={this.acceptInvitation.bind(this, 'decline')}>{this.state.isReplyBtnLoading ? 'Loading…' : 'Not Attending'}</Button>
            </div>
        );
    }

    }

    render() {
        return (
            <EventLayout location={this.props.location}>
            {!this.state.data ? (
                <div className="text-center" style={{margin:`auto`, padding:`1.5rem 0`}}>
                    {`Loading Event details...`}
                    <BarLoader
                        sizeUnit={"px"}
                        width={250}
                        color={'#123abc'}
                        css={override}
                    />
                </div>
            ) : (
                <Jumbotron>
                    {/*
                    <Media>
                        <Media src={this.state.data.main_image} style={{maxWidth: `100%`,width: `100%`}}/>
                    </Media> 
                    */}
                    <h4 className="display-3">
                        {this.state.data.title}
                    </h4>
                    <hr className="my-2" />
                    <font style={{fontSize:`1rem`}}><i>{moment(this.state.data.event_datetime).format('DD MMMM YYYY H:mm')}</i></font>
                    <br />
                    <br />
                    <div className="d-flex justify-content-center">
                        <img src={this.state.data.main_image}/>
                    </div>
                    <div className="lead">
                        { ReactHtmlParser(this.state.data.content) }
                    </div>
                    <div className="clearfix d-flex justify-content-center" >
                    {this.renderInvitationResponseBtn()}
                    </div>
                </Jumbotron>
            )}
                
            </EventLayout>
        )
    }
}

// NOT USED
// export class ViewContent extends Component {
//     constructor(props){
//         super(props)

//         console.log(props)
//         this.state = {
//             data : this.props.data
//         }
//     }

//   render() {

//     const value = this.state.data
//     return (
//         <Jumbotron>
//             <Media>
//                 <Media src={value.image} style={{maxWidth: `100%`,width: `100%`}}/>
//             </Media>
//             <h3 className="display-3">
//                 {value.title}
//             </h3>
//             <hr className="my-2" />
//             <font style={{fontSize:`1rem`}}><i>{value.start}</i></font>
//             <br />
//             <br />

//             <p className="lead">
//                 { ReactHtmlParser(value.content) }
//             </p>
//             <p >
//                 { value.video ? (<ReactPlayer url={value.video} playing controls={true} style={{margin:`auto`}}/>) : null}
//             </p>
//             <br/>
//         </Jumbotron>
//     )
//   }
// }